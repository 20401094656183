<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">
        巴林右旗{{ villageName }}农牧业统计
      </div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <!-- 种植种类 -->
        <div class="left_box">
          <div class="title" @click="goToFarmalPage()"><span>种植种类</span></div>
          <bing-tu :value="data1" />
        </div>

        <!-- 养植类 -->
        <div class="left_box">
          <div class="title" @click="goToAnimalPage()"><span>存栏统计</span></div>
          <bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <da-ban-map v-if="villageName == '大板镇'" />
          <su-bo-ri-ga v-if="villageName == '索博日嘎镇'" />
          <xing-fu-zhi-lu v-if="villageName == '幸福之路苏木'" />
          <ba-yan-hu-shuo-hamlet v-if="villageName == '巴彦琥硕镇'" />
          <cha-gan-mu-lun-hamlet v-if="villageName == '查干沐沦苏木'" />
          <mei-dian-hua v-if="villageName == '大板煤电化基地'" />

          <ba-yan-ta-la v-if="villageName == '巴彦塔拉苏木'" />
          <cha-gan-nuo-er v-if="villageName == '查干诺尔镇'" />
          <bao-ri-wu-su v-if="villageName == '宝日勿苏镇'" />
          <xi-la-mu-lun v-if="villageName == '西拉沐沦苏木'" />

          <!-- <div class="map">
            <img src="../../../../assets/images/ba-lin-you-qi/map.png" alt="">
          </div> -->
        </div>

        <div class="context_box" v-if="villageName == '大板镇'">
          全镇总土地面积 1925 平方公里，
          全镇大小畜存栏 22.6 万头只，其中大畜牛 3.58 万头。
          永久性棚圈建设 5.3 万平方米，青贮窖建设 8300 立方米。
          至 2022 年人口 77003 人。总人口中男性 38520 人。
        </div>
        <div class="context_box" v-if="villageName == '索博日嘎镇'">
          索博日嘎镇行政区域面积1402平方千米。2018年末，索博日嘎镇户籍人口22791人。
          2011年末，索博日嘎镇林地面积30.4万亩，其中经济林有500亩，
          防护林或者公益林有30万亩，森林覆盖率30%。
        </div>
        <div class="context_box" v-if="villageName == '幸福之路苏木'">
          幸福之路苏木行政区域面积965平方千米。2018年末，幸福之路苏木户籍人口10228人。
          2018年，幸福之路苏木有工业企业7个，有营业面积超过50平方米以上的综合商店或超市7个。
        </div>
        <div class="context_box" v-if="villageName == '巴彦琥硕镇'">
          巴彦琥硕镇有丰富的山杏资源，现有面积12万亩，其中：自然林5万亩，人工林7万亩，
          现进入丰产期的有8.2万亩，平均年产山杏核70万公斤左右，创经济价值150万元。
        </div>
        <div class="context_box" v-if="villageName == '查干沐沦苏木'">
          查干沐沦苏木行政区域面积901平方千米。2018年末，查干沐沦苏木户籍人口7302人。
          2018年，查干沐沦苏木有工业企业5个，其中规模以上1个；有营业面积超过50平方米以上的综合商店或超市9个。
        </div>

        <div class="context_box" v-if="villageName == '巴彦塔拉苏木'">
          巴彦塔拉苏木区域面积613.86平方千米。截至2018年末，巴彦塔拉苏木户籍人口18900人。
          截至2018年末，巴彦塔拉苏木有工业企业1家，其中规模以上工业企业1家。
        </div>

        <div class="context_box" v-if="villageName == '查干诺尔镇'">
          查干诺尔镇行政区域面积964平方千米。2018年末，查干诺尔镇户籍人口16957人。
          2018年，大板镇有工业企业3个，有营业面积超过50平方米以上的综合商店或超市21个。
        </div>

        <div class="context_box" v-if="villageName == '宝日勿苏镇'">
          宝日勿苏镇行政区域面积1165平方千米。2018年末，宝日勿苏镇户籍人口16957人。
          2018年，宝日勿苏镇有工业企业4个，有营业面积超过50平方米以上的综合商店或超市31个。
        </div>

        <div class="context_box" v-if="villageName == '西拉沐沦苏木'">
          西拉沐沦苏木行政区域面积1271平方千米。2018年末，西拉沐沦苏木户籍人口13874人。
          2018年，西拉沐沦苏木有工业企业6个，有营业面积超过50平方米以上的综合商店或超市40个。
        </div>

        <div class="bottom_2_box">
          <div class="bottom_left_box" @click="goToPersonStateDetail()">
            <div class="title"><span>人口</span></div>
            <div class="item_box">
              <div class="item" v-for="(item, index) in personList" :key="index">
                {{ item.name }}: {{ item.value }}人
              </div>
            </div>
          </div>

          <div class="bottom_right_box" @click="goToMashineStateDetail()">
            <div class="title"><span>机械化使用</span></div>
            <div class="item_box">
              <div class="item" v-for="(item, index) in mashineList" :key="index">
                {{ item.name }}: {{ item.value }}辆
              </div>
            </div>
          </div>  
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="pai_hang">
          <div class="title"><span>排行</span></div>

          <div class="tab_box">
            <div class="tab_item" :class="{ activeColor: activeIndex == 0 }" @click="clickTab('0')">水稻</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 1 }" @click="clickTab('1')">甜菜</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 2 }" @click="clickTab('2')">西瓜</div>
            <div class="tab_item" :class="{ activeColor: activeIndex == 3 }" @click="clickTab('3')">香瓜</div>
          </div>

          <div class="list_box">
            <div class="list_item" v-for="(item, index) in list" :key="index">
              <span v-if="index < 9">0{{ index+1 }}</span> 
              <span v-else>{{ index+1 }}</span> 

              <div class="name" @click="goToHamLetPage(item.name)">{{ item.name }}</div> 

              <div class="bgc">
                <div class="c" :style="{width: (item.value / 40) + '%'}"></div>
              </div>

              <div class="value">{{ item.value }} 吨</div> 
            </div>
          </div>

        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'

  import DaBanMap from '../maps/daBanMap.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
      DaBanMap, 
      SuBoRiGa, 
      XingFuZhiLu, 
      BaYanHuShuoHamlet,
      ChaGanMuLunHamlet,
      MeiDianHua,
      BaYanTaLa,
      BaoRiWuSu,
      XiLaMuLun,
      ChaGanNuoEr, 
      kongXinBingTu,
    },
    data() {
      return {
        activeIndex: 0,
        mapName: '',

        data1: {},
        data2: {},
        villageName: '',
        personList: [
          { name: '14岁以下', value: 12817 },
          { name: '15 - 64岁', value: 59606 },
          { name: '65岁以上', value: 4580 }
        ],
        mashineList: [
          { name: ' 四轮车', value: 3500 },
          { name: '大型货车', value: 18 },
          { name: '大型拖拉机', value: 91 },
          { name: '播种机械', value: 2800 },
          { name: '打草机械', value: 580 },
          { name: '家用车辆', value: 450 },
        ],

        list: [
          { name: '红旗嘎查', value: 3289 },
          { name: '吉布吐嘎查', value: 2578 },
          { name: '西哈嘎查', value: 1956 },
          { name: '十家子嘎查', value: 1664 },
          { name: '查干诺尔嘎查', value: 1239 },
          { name: '浩饶沁嘎查', value: 1217 },
          { name: '大冷嘎查', value: 1189 },
          { name: '古力古台嘎查', value: 1078 },
          { name: '苏艾力嘎查', value: 819 },
          { name: '太布呆嘎查', value: 784 },
          { name: '昭胡都格嘎查', value: 526 },
          { name: '新达冷村', value: 517 },
          { name: '苏艾里嘎查', value: 489 },
          { name: '西拉汰沦厚查', value: 475 },
          { name: '巴彦汉嘎查', value: 379 },
          { name: '温根吐嘎查', value: 334 },
          { name: '翁根图毛都嘎查', value: 321 },
          { name: '红星村', value: 267 },
          { name: '莫日古其格', value: 229 },
          { name: '独希嘎查', value: 219 },
          { name: '新立村', value: 207 },
          { name: '友爱村', value: 198 },
          { name: '益斯毛都嘎查', value: 169 },
          { name: '准宝日嘎查', value: 157 },
          { name: '巴罕村', value: 142 },
          { name: '套白村', value: 115 }
        ],
        list1: [
          { name: '红旗嘎查', value: 3289 },
          { name: '吉布吐嘎查', value: 2578 },
          { name: '西哈嘎查', value: 1956 },
          { name: '十家子嘎查', value: 1664 },
          { name: '查干诺尔嘎查', value: 1239 },
          { name: '浩饶沁嘎查', value: 1217 },
          { name: '大冷嘎查', value: 1189 },
          { name: '古力古台嘎查', value: 1078 },
          { name: '苏艾力嘎查', value: 819 },
          { name: '太布呆嘎查', value: 784 },
          { name: '昭胡都格嘎查', value: 526 },
          { name: '新达冷村', value: 517 },
          { name: '苏艾里嘎查', value: 489 },
          { name: '西拉汰沦厚查', value: 475 },
          { name: '巴彦汉嘎查', value: 379 },
          { name: '温根吐嘎查', value: 334 },
          { name: '翁根图毛都嘎查', value: 321 },
          { name: '红星村', value: 267 },
          { name: '莫日古其格', value: 229 },
          { name: '独希嘎查', value: 219 },
          { name: '新立村', value: 207 },
          { name: '友爱村', value: 198 },
          { name: '益斯毛都嘎查', value: 169 },
          { name: '准宝日嘎查', value: 157 },
          { name: '巴罕村', value: 142 },
          { name: '套白村', value: 115 }
        ],

        list2: [
          { name: '昭胡都格嘎查', value: 1230 },
          { name: '新达冷村', value: 1139 },
          { name: '苏艾里嘎查', value: 1024 },
          { name: '西拉汰沦厚查', value: 983 },
          { name: '巴彦汉嘎查', value: 974 },
          { name: '温根吐嘎查', value: 889 },
          { name: '浩饶沁嘎查', value: 853 },
          { name: '大冷嘎查', value: 845 },
          { name: '古力古台嘎查', value: 814 },
          { name: '红旗嘎查', value: 769 },
          { name: '吉布吐嘎查', value: 748 },
          { name: '西哈嘎查', value: 698 },
          { name: '温根吐嘎查', value: 683 },
          { name: '十家子嘎查', value: 669 },
          { name: '苏艾力嘎查', value: 653 },
          { name: '太布呆嘎查', value: 643 },
          { name: '翁根图毛都嘎查', value: 600 },
          { name: '红星村', value: 550 },
          { name: '莫日古其格', value: 500 },
          { name: '独希嘎查', value: 300 },
          { name: '新立村', value: 250 },
          { name: '友爱村', value: 200 },
          { name: '益斯毛都嘎查', value: 100 },
          { name: '准宝日嘎查', value: 90 },
          { name: '巴罕村', value: 0 },
          { name: '套白村', value: 0 },
        ],

        list3: [
          { name: '翁根图毛都嘎查', value: 2567 },
          { name: '红星村', value: 2457 },
          { name: '莫日古其格', value: 2345 },
          { name: '独希嘎查', value: 2133 },
          { name: '新立村', value: 2013 },
          { name: '友爱村', value: 1974 },
          { name: '益斯毛都嘎查', value: 1968 },
          { name: '准宝日嘎查', value: 1956 },
          { name: '巴罕村', value: 1867 },
          { name: '套白村', value: 1849 },
          { name: '浩饶沁嘎查', value: 1770 },
          { name: '大冷嘎查', value: 1639 },
          { name: '古力古台嘎查', value: 1426 },
          { name: '红旗嘎查', value: 1283 },
          { name: '吉布吐嘎查', value: 1174 },
          { name: '西哈嘎查', value: 1045 },
          { name: '温根吐嘎查', value: 924 },
          { name: '十家子嘎查', value: 916 },
          { name: '苏艾力嘎查', value: 905 },
          { name: '太布呆嘎查', value: 895 },
          { name: '昭胡都格嘎查', value: 789 },
          { name: '新达冷村', value: 765},
          { name: '苏艾里嘎查', value: 761 },
          { name: '西拉汰沦厚查', value: 750 },
          { name: '巴彦汉嘎查', value: 723 },
          
        ],

        list4: [
        { name: '大冷嘎查', value: 2245 },
          { name: '古力古台嘎查', value: 2187 },
          { name: '红旗嘎查', value: 2013 },
          { name: '吉布吐嘎查', value: 1987 },
          { name: '西哈嘎查', value: 1957 },
          { name: '十家子嘎查', value: 1859 },
          { name: '浩饶沁嘎查', value: 1670 },
          { name: '苏艾力嘎查', value: 957 },
          { name: '太布呆嘎查', value: 889 },
          { name: '温根吐嘎查', value: 846 },
          { name: '昭胡都格嘎查', value: 789 },
          { name: '新达冷村', value: 745},
          { name: '苏艾里嘎查', value: 721 },
          { name: '西拉汰沦厚查', value: 710 },
          { name: '巴彦汉嘎查', value: 703 },
          { name: '翁根图毛都嘎查', value: 629 },
          { name: '红星村', value: 589 },
          { name: '莫日古其格', value: 456 },
          { name: '独希嘎查', value: 410 },
          { name: '新立村', value: 340 },
          { name: '友爱村', value: 325 },
          { name: '益斯毛都嘎查', value: 230 },
          { name: '准宝日嘎查', value: 160 },
          { name: '巴罕村', value: 154 },
          { name: '套白村', value: 87 },
        ],

        list5: [
          { name: '红星村', value: 2699 },
          { name: '莫日古其格', value: 2682 },
          { name: '独希嘎查', value: 2678 },
          { name: '新立村', value: 2639 },
          { name: '友爱村', value: 2629 },
          { name: '益斯毛都嘎查', value: 2587 },
          { name: '准宝日嘎查', value: 2560 },
          { name: '巴罕村', value: 2559 },
          { name: '套白村', value: 2546 },
          { name: '浩饶沁嘎查', value: 2467 },
          { name: '大冷嘎查', value: 2356 },
          { name: '古力古台嘎查', value: 2254 },
          { name: '红旗嘎查', value: 2134 },
          { name: '吉布吐嘎查', value: 2014 },
          { name: '西哈嘎查', value: 1845 },
          { name: '温根吐嘎查', value: 1624 },
          { name: '十家子嘎查', value: 1516 },
          { name: '苏艾力嘎查', value: 1405 },
          { name: '太布呆嘎查', value: 1395 },
          { name: '昭胡都格嘎查', value: 969 },
          { name: '新达冷村', value: 865},
          { name: '苏艾里嘎查', value: 761 },
          { name: '西拉汰沦厚查', value: 750 },
          { name: '巴彦汉嘎查', value: 703 },
          { name: '翁根图毛都嘎查', value: 559 }
        ],

        list6: [
          { name: '红旗嘎查', value: 1689 },
          { name: '吉布吐嘎查', value: 1578 },
          { name: '西哈嘎查', value: 1456 },
          { name: '十家子嘎查', value: 1364 },
          { name: '查干诺尔嘎查', value: 1339 },
          { name: '浩饶沁嘎查', value: 1217 },
          { name: '大冷嘎查', value: 1189 },
          { name: '古力古台嘎查', value: 1078 },
          { name: '苏艾力嘎查', value: 819 },
          { name: '太布呆嘎查', value: 784 },
          { name: '昭胡都格嘎查', value: 526 },
          { name: '新达冷村', value: 517 },
          { name: '苏艾里嘎查', value: 489 },
          { name: '西拉汰沦厚查', value: 475 },
          { name: '巴彦汉嘎查', value: 379 },
          { name: '温根吐嘎查', value: 334 },
          { name: '翁根图毛都嘎查', value: 321 },
          { name: '红星村', value: 267 },
          { name: '莫日古其格', value: 229 },
          { name: '独希嘎查', value: 219 },
          { name: '新立村', value: 207 },
          { name: '友爱村', value: 198 },
          { name: '益斯毛都嘎查', value: 169 },
          { name: '准宝日嘎查', value: 157 },
          { name: '巴罕村', value: 142 },
          { name: '套白村', value: 115 }
        ]
      }
    },
    mounted() {
      this.villageName = this.$route.params.val
      setTimeout(() => {
        this.initChart()
      }, 100)

      this.$bus.$on('setData', val => {
        // this.mapName = '巴林右旗' + val.mapName
        // this.data1 = val.zhong_zhi_zhong_lei
        // this.data2 = val.yang_zhi_lei
        // this.personList = val.person_data
        this.mashineList = val.mashine_data
      })

      // 发送点击事件
      this.$bus.$on('goToDetailMap', val => {
        this.$router.push({
          path: "/bigscreen/villagePage2" + val
        })
      })    
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '种植种类',
          unit: '万亩',
          data: [
            {"name": "水稻", "value": 2.6, "unit": ''},
            {"name": "甜菜", "value": 1.23, "unit": ''},
            {"name": "西瓜、香瓜", "value": 1.5, "unit": ''},
          ]
        }

        this.data2 = {
          title: '存栏统计',
          unit: '万头',
          data: [
            {"name": "牛存栏", "value": 14.9, "unit": ''},
            {"name": "羊存栏", "value": 267.7, "unit": ''}
          ]
        }

      },

      // 跳转到 大板镇 农业统计页面 
      goToFarmalPage() {
        this.$router.push({
          path: "/bigscreen/villageFarmal" + this.villageName
        })
      },

      // 跳转到 大板镇 牧业统计页面 
      goToAnimalPage() {
        this.$router.push({
          path: "/bigscreen/villageAnimal" + this.villageName
        })
      },


      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `巴林右旗${this.villageName}人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `巴林右旗${this.villageName}机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      },

      // 跳转到指定嘎查页面
      goToHamLetPage(val) {
        this.$router.push({
          path: "/bigscreen/hamletPage" + val
        })
      }
    }
  }
</script>

<style scoped lang="less">
  // @import url('../../../../assets/css/bigscreen.less');
  .activeColor {color: rgb(13, 246, 94) !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>